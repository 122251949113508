import React from 'react';

import NewsArticle from '../../../components/news-article/news-article.component'

const Article = () => (
  <NewsArticle>
    <h1 className='page-title'>Financial Reporter: HSBC hits 750 broker milestone</h1>
    <img src='https://www.financialreporter.co.uk/images/660x350/4875-iStock_000027246086_Double.jpg' alt='HSBC building' title='HSBC building photo' className='news-article__lead-image'/>
    <blockquote>"This week we hit another significant milestone, having on-boarded our 750th broker firm since we first provided access to mortgage intermediaries just a few years ago."</blockquote>
    <p>In 2021, the bank more than doubled its broker partner numbers from 277 to 640.</p>
    <p>Last year, HSBC provided over 28,000 first-time buyer mortgages and overall provide the funding for the purchase of 90,000 homes.</p>
    <p>The bank provided £27.6bn of gross new mortgage lending in 2021 vs £24.5bn in 2020.</p>
    <p><strong>Chris Pearson, HSBC UK’s head of intermediary mortgages, commented:</strong><br></br>"This week we hit another significant milestone, having on-boarded our 750th broker firm since we first provided access to mortgage intermediaries just a few years ago. We more than doubled our intermediary panel last year, and added the equivalent of 30 full time colleagues to the Intermediaries team to help with that growth, and handled over 400,000 broker calls and live chat conversations. Intermediaries are extremely important to us and we know they value having access to our mortgages.</p>
    <p>"Life is starting to return to normal in many ways, but it is worth repeating how proud I am of the role our HSBC UK Mortgages teams and the mortgage intermediary sector played in keeping the housing market moving during the depths of the pandemic. Everyone needed to change how they worked, even those who were working from home anyway. The movement from some lenders in pulling out of certain markets and the sheer change in policies across the board created a constantly changing landscape that brokers did well to navigate.</p>
    <p>"Last year started with the third national lockdown and ended with an increase in the Bank of England’s Base Rate, and there was much that happened in between. We have, of course, seen two already in 2022, and more may be on the cards. For us, 2021 was a real team effort, and together we helped almost 30,000 people buy their first home. Buying your first home and getting the keys is such a fantastic feeling, and I am pleased, together, we have been able to help so many buy their first home and experience that feeling for themselves.</p>
    <p>"We have seen our team of BDMs on Zoom and on the road supporting our broker networks, our policy-makers and underwriters making sure our policies are reflecting what is going on in the world and applying them to applications, whilst not forgetting those in the product team who enabled brokers to offer our first sub-1% mortgage rate in over 5 years.</p>
    <p>"Reaffirming our commitment to the broker sector, in 2021 we extended the availability of our Buy To Let mortgages to our broker networks, giving them access to our BTL mortgages for the first time. We have also added a revamped Intermediary website, and with feedback from brokers we have worked to provide a modern, intuitive and responsive replacement, which includes an improved search function, new resources page and improved broker support page.</p>
    <p>"Throughout the year, the HSBC UK Buying A Home team has received numerous industry awards, over 30 in total. We always do our best to provide great customer service alongside competitive products, so I am particularly proud to be awarded 5 stars for customer service in the Financial Adviser Service Awards. I think this is a great achievement as four years ago we were awarded only one star. It is wonderful for the relentless efforts of my team to be independently recognised in this way.</p>
    <p>We will continue working hard to bring more broker firms on board and invest in providing the best possible products and service for them to offer their customers. Intermediaries are extremely important to us and we know they value having access to our mortgages. We will continue working hard to bring more broker firms on board and invest in providing the best possible products and service for them to offer their customers."</p>
  </NewsArticle>
)

export default Article